import './src/styles/global.css';
import { init } from 'cookie-though'
init({
    policies: [
        {
            id: 'essential',
            label: 'Essential Cookies',
            description: 'Cookies for things like remembering these cookie preferences.',
            category: 'essential'
        },
        {
            id: 'ecommerce',
            label: 'Store Cookies',
            description: 'Cookies for your cart and the checkout process.  Disabling these cookies will prevent us from saving your cart if you leave.',
            category: 'functional'
        },
        {
            id: 'analytics',
            label: 'Marketing Cookies',
            description: 'Cookies that help us understand your behavior.  We will never sell this data or use it to personally identify you.',
            category: 'marketing'
        },
        {
            id: 'debug',
            label: 'Technical Cookies',
            description: 'Cookies that help us fix problems in this website.  Your data will never be sold and is automatically deleted when it\'s no longer useful.',
            category: 'statistics'
        }
    ],
    essentialLabel: 'Always on',
    permissionLabels: {
        accept: "Accept",
        acceptAll: "Accept all",
        decline: "Decline"
    },
    header: {
        title: "Cookies, matey?",
        subTitle: "We need a few cookies to stay afloat.",
        description: "We use cookies in this website.  We hate trackers too, and we won't sell data gathered using cookies to another company.  Click the cookie at the bottom of the page to change your choices later."
    },
    customizeLabel: "Customize"
})    